<template>
  <div class="continue-shipping">
    <el-dialog
      :show-close="false"
      :visible="visible"
      center
      class="address-edit-dia"
      width="45%"
      @close="cancel"
    >
      <div class="dia-header">
        <span>{{ $t("casesDetail.casesChildren.nextProduction.wxtz") }}</span>
        <i class="el-icon-close" @click.stop="cancel" />
        <!--        <img
          @click.stop="cancel"
          class="close-dia"
          src="../../common/imgs/expressage_birdie/dia_close.png"
          alt=""
        />-->
      </div>
      <div class="dia-content">
        <div class="shipping-tips">
          <p>
            <span>
              {{ $t("casesDetail.casesDetail.shzz") }}
              {{ get(curePlanInfo, "maxillary") }}
              {{ $t("casesDetail.casesDetail.bu") }}，
              {{ $t("casesDetail.casesDetail.xhzz") }}
              {{ get(curePlanInfo, "mandibular") }}
              {{ $t("casesDetail.casesDetail.bu") }}
            </span>
            ,
            <span>
              {{ $t("casesDetail.casesDetail.gcffz") }}
              {{ $t("casesDetail.casesDetail.shd") }}
              {{ get(curePlanInfo, "nowStepUpjaw") }}
              {{ $t("casesDetail.casesDetail.bu") }}，
              {{ $t("casesDetail.casesDetail.xhd") }}
              {{ get(curePlanInfo, "nowStepDownjaw") }}
              {{ $t("casesDetail.casesDetail.bu") }}
            </span>
          </p>
          <p>
            <span class="fz16rem main_theme_color_333"
              >{{ $t("casesDetail.casesDetail.bcjg") }}：</span
            >
            <span
              v-show="curePlanInfo.nowStepUpjaw != curePlanInfo.nextStepUpjaw"
              class="ml50"
            >
              {{ $t("casesDetail.casesDetail.shd") }}
              {{ get(curePlanInfo, "nowStepUpjaw") + 1 + " - "
              }}{{ get(curePlanInfo, "nextStepUpjaw") }}
              {{ $t("casesDetail.casesDetail.bu") }}
            </span>
            <span
              v-show="
                curePlanInfo.nowStepDownjaw != curePlanInfo.nextStepDownjaw
              "
              class="ml40"
            >
              {{ $t("casesDetail.casesDetail.xhd") }}
              {{ get(curePlanInfo, "nowStepDownjaw") + 1 + " -" }}
              {{ get(curePlanInfo, "nextStepDownjaw") }}
              {{ $t("casesDetail.casesDetail.bu") }}
            </span>
          </p>
        </div>
        <div class="receive-content">
          <div class="f_single">
            <div
              :style="language === 'en_US' ? 'width: 2rem' : ''"
              class="f_single_left"
            >
              <span class="d_require">●</span>
              {{ $t("cases.createUser.hzjsyt") }}：
            </div>
            <div
              class="f_single_right"
              style="display: flex; align-items: center"
            >
              <el-radio v-model="userForm.receiveType" label="1"
                >{{ $t("cases.createUser.zssh") }}
              </el-radio>
              <el-radio v-model="userForm.receiveType" label="3">
                {{ $t("yssh.yssh") }}
              </el-radio>
              <el-radio v-model="userForm.receiveType" label="2"
                >{{ $t("cases.createUser.trsh") }}
              </el-radio>
            </div>
          </div>
          <ul v-show="userForm.receiveType === '2'">
            <li class="f_single">
              <div
                :style="language === 'en_US' ? 'width: 2rem' : ''"
                class="f_single_left"
              >
                {{ $t("cases.createUser.znjx") }}：
              </div>
              <div class="iup_box">
                <div class="f_single_right">
                  <textarea
                    v-model="address"
                    :placeholder="$t('cases.createUser.znjxplaceholder')"
                    class="message-add"
                    rows="3"
                  />
                  <a class="message-parse" @click="getMessage">{{
                    $t("cases.createUser.jx")
                  }}</a>
                  <span class="message-reset" @click="resetMessage">{{
                    $t("cases.createUser.qk")
                  }}</span>
                </div>
              </div>
            </li>
            <li class="f_single">
              <div
                :style="language === 'en_US' ? 'width: 2rem' : ''"
                class="f_single_left"
              >
                <span class="d_require">●</span>
                {{ $t("cases.createUser.sjrxm") }}：
              </div>
              <div class="f_single_right">
                <input
                  v-model="userForm.receiver"
                  :placeholder="$t('cases.createUser.qtxsjrxm')"
                />
              </div>
            </li>

            <li class="f_single">
              <div
                :style="language === 'en_US' ? 'width: 2rem' : ''"
                class="f_single_left"
              >
                <span class="d_require">●</span>
                {{ $t("cases.createUser.sjrsjhm") }}：
              </div>
              <div class="f_single_right" style="display: flex">
                <el-select
                  v-model="userForm.contactWay"
                  style="width: 1.5rem"
                  @change="changeContactWay"
                >
                  <el-option
                    v-for="item in contactWayList"
                    :key="item.key"
                    :label="item.value"
                    :value="item.key"
                  >
                  </el-option>
                </el-select>
                <input
                  v-if="userForm.contactWay == 1"
                  v-model="userForm.phone"
                  :placeholder="$t('cases.createUser.qsrsjrsjhm')"
                  style="margin-left: 0.2rem; width: 3.4rem;"
                  type="number"
                />
                <div
                  v-if="userForm.contactWay == 2"
                  style="margin-left: 0.2rem; display: flex; align-items: center"
                >
                  <input
                    v-model="userForm.phoneArea"
                    :placeholder="$t('birdie.qh')"
                    style="width: 1.2rem; margin-right: 0.2rem;"
                    type="number"
                  />
                  <input
                    v-model="userForm.phone"
                    :placeholder="$t('birdie.dhhm')"
                    style="width: 2rem"
                    type="number"
                  />
                </div>
              </div>
            </li>

            <li class="f_single" style="align-items: self-start">
              <div
                :style="language === 'en_US' ? 'width: 2rem' : ''"
                class="f_single_left"
                style="padding-top: 0.1rem"
              >
                <span class="d_require">●</span>
                {{ $t("cases.createUser.dz") }}：
              </div>
              <div class="f_single_right">
                <div class="select_box">
                  <el-cascader
                    v-model="userForm.provinces"
                    :options="cityData"
                    :placeholder="$t('birdie.qxzdq')"
                    :props="{
                      value: 'id',
                      label: 'name',
                      children: 'sub'
                    }"
                    class="input_width"
                  ></el-cascader>
                </div>
              </div>
            </li>
            <li class="f_single">
              <div
                :style="language === 'en_US' ? 'width: 2rem' : ''"
                class="f_single_left"
                style="padding-top: 0.1rem"
              >
                <span class="d_require">●</span>
                {{ $t("cases.createUser.xxdz") }}：
              </div>
              <div class="f_single_right">
                <input
                  v-model="userForm.address"
                  :placeholder="$t('cases.createUser.xxdz')"
                  type="text"
                />
              </div>
            </li>
          </ul>
          <div v-if="userForm.receiveType === '3'" class="doctor-address">
            <div
              :style="language === 'en_US' ? 'width: 2.4rem' : ''"
              class="address-label"
            >
              {{ $t("yssh.ysshdz") }}：
            </div>
            <div class="single_right">
              <div
                :class="
                  doctorAddressId === ''
                    ? 'doctor-select'
                    : 'doctor-select-active'
                "
                :title="
                  doctorAddressId === ''
                    ? $t('yssh.djdzxz')
                    : renderAddressText()
                "
                @click="showDoctorAddressDia"
              >
                {{
                  doctorAddressId === ""
                    ? $t("yssh.djdzxz")
                    : renderAddressText()
                }}
              </div>
              <span @click="showDoctorAddressDia">{{ $t("yssh.dzxz") }}</span>
            </div>
          </div>
          <div v-if="userForm.receiveType === '1'" class="receive-address">
            <div
              :style="language === 'en_US' ? 'width: 2rem' : ''"
              class="address-label"
            >
              {{ $t("braceReissue.dzxz") }}：
            </div>
            <el-select
              v-model="userForm.receiveAddressId"
              :placeholder="$t('braceReissue.qxzzsshdz')"
              class="address-select"
              clearable
            >
              <el-option
                v-for="item in clinicAddressList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                style="max-width: 9rem;"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="before-info">
          <p>
            <span class="label"> {{ $t("yssh.spjzqshdz") }}: </span>
            <span :title="getBeforeReceiveAddress()">
              {{ getBeforeReceiveAddress() }}
            </span>
          </p>
          <p class="service-tips">
            {{ $t("casesDetail.casesDetail.ggdz") }}
          </p>
        </div>
      </div>
      <div class="dia-footer">
        <div class="cancel" @click="cancel">
          {{ $t("casesDetail.casesChildren.casesDoKeep.qx") }}
        </div>
        <div class="confirm" @click="confirm">
          {{ $t("casesDetail.casesChildren.casesDoKeep.qd") }}
        </div>
      </div>
    </el-dialog>
    <el-dialog
      :show-close="false"
      :visible="showDoctorAddress"
      class="address-dia"
      width="588px"
      @close="showDoctorAddress = false"
    >
      <div class="address-dia-header">
        <div class="title">
          {{ $t("yssh.dzxz") }}
          <div class="add-address" @click="addDoctorAddress">
            <img alt="" src="../../common/imgs/personal/position.png" />
            {{ $t("yssh.tjdz") }}
          </div>
        </div>
        <img
          alt=""
          class="close-dia"
          src="../../common/imgs/expressage_birdie/dia_close.png"
          @click.stop="showDoctorAddress = false"
        />
      </div>
      <div class="address-dia-content">
        <div v-if="!addressList.length" class="no-data">
          <img alt="" src="../../common/imgs/personal/no-address.png" />
          <span>{{ $t("yssh.nzwtjrhshdz") }}</span>
        </div>
        <div
          v-for="(item, index) in addressList"
          :key="index"
          class="address-item"
        >
          <AddressCard
            :info="item"
            :isCreated="true"
            :showDefault="true"
            @cancel="editCurrent"
            @confirm="choosenCurrent"
          />
        </div>
      </div>
      <div class="address-dia-footer"></div>
    </el-dialog>
    <AddressEdit
      :editId="editId"
      :isEdit="isEdit"
      :visible="editVisible"
      @cancel="editVisible = false"
      @confirm="confirmAddress"
    />
  </div>
</template>

<script>
import { clinicAddress, PublicSendAddressList } from "common/api/cases";
import { get, extend, each, map, every, keys, pickBy, split } from "lodash";
import { mapState } from "vuex";
import AddressParse from "address-parse";
import { getCityList } from "common/api/public";
import { notifyMsg } from "common/js/util";
import AddressCard from "components/address-card/index.vue";
import AddressEdit from "components/address-edit/index.vue";

export default {
  props: {
    visible: {
      required: true
    },
    info: {
      required: true
    }
  },
  data() {
    return {
      editId: "",
      isEdit: false,
      editVisible: false,
      addressList: [],
      showDoctorAddress: false,
      curePlanInfo: {},
      address: "",
      doctorAddressId: "",
      userForm: {
        receiver: "",
        receiveType: "1",
        receiveAddressId: "",
        mobile: "",
        provinces: "", //省
        address: "", //地址
        phoneArea: "",
        contactWay: "1"
      },
      contactWayList: [
        { idx: 0, key: "1", value: this.$t("cases.createUser.sjhm") },
        { idx: 1, key: "2", value: this.$t("birdie.gddh") }
      ],
      clinicAddressList: [],
      cityData: JSON.parse(sessionStorage.getItem("cityData")),
      CITY: []
    };
  },
  components: {
    AddressEdit,
    AddressCard
  },
  watch: {
    info(nval) {
      this.getSendAddressList();
      const { receiveType, receiveAddress, receiveAddressId } = nval;
      const {
        area,
        city,
        province,
        receiveUser,
        contactWay,
        phone,
        phoneArea,
        address
      } = receiveAddress;
      if (receiveType === "1") {
        this.userForm = {
          ...this.userForm,
          receiveType,
          receiveAddressId
        };
      } else if (receiveType === "2") {
        this.userForm = {
          receiveType,
          address,
          contactWay,
          phone,
          phoneArea,
          provinces: [province, city, area],
          receiver: receiveUser
        };
      } else if (receiveType === "3") {
        this.userForm = {
          ...this.userForm,
          receiveAddressId,
          receiveType
        };
        this.doctorAddressInfo = receiveAddress;
      }
      this.curePlanInfo = nval["curePlanInfo"];
    },
    "userForm.receiveType": {
      handler: function(val) {
        if (val == "1") {
          this.userForm.receiver = "";
          this.userForm.phoneArea = this.userForm.receiverMobile = "";
          this.userForm.contactWay = "1";
          this.userForm.provinces = this.userForm.city = this.userForm.area = this.userForm.address =
            "";
        } else if (val === "2") {
          this.doctorAddressId = "";
          this.doctorAddressInfo = {};
          if (this.info.receiveType === "2") {
            this.userForm = {
              ...this.userForm,
              receiveAddressId: "",
              provinces: [
                get(this.info.receiveAddress, "province") || "",
                get(this.info.receiveAddress, "city") || "",
                get(this.info.receiveAddress, "area") || ""
              ],
              address: get(this.info.receiveAddress, "address") || "",
              receiverMobile: get(this.info.receiveAddress, "phone") || "",
              phoneArea: get(this.info.receiveAddress, "phoneArea") || "",
              receiver: get(this.info.receiveAddress, "receiver"),
              contactWay: get(this.info.receiveAddress, "contactWay") || "1",
              receiver: get(this.info.receiveAddress, "receiveUser") || ""
            };
          } else {
            this.userForm.receiver = "";
            this.userForm.phoneArea = this.userForm.receiverMobile = "";
            this.userForm.contactWay = "1";
            this.userForm.provinces = "";
          }
        } else if (val === "3") {
          if (this.info.receiveType === "3") {
            this.doctorAddressId = this.info.receiveAddressId;
            this.doctorAddressInfo = this.info.otherReceiveAddress;
          }
          this.userForm.receiveAddressId = "";
          this.userForm.address = "";
          this.userForm.receiver = "";
          this.userForm.phoneArea = this.userForm.receiverMobile = "";
          this.userForm.contactWay = "1";
          this.userForm.provinces = "";
        }
      }
    }
  },
  computed: {
    ...mapState({
      language: state => state.language
    })
  },
  mounted() {
    this.getCity();
  },
  methods: {
    get,
    confirmAddress() {
      this.editVisible = false;
      this.getSendAddressList();
    },
    choosenCurrent(info) {
      this.doctorAddressInfo = info;
      this.doctorAddressId = info.id;
      this.showDoctorAddress = false;
    },
    addDoctorAddress() {
      this.isEdit = false;
      this.editVisible = true;
    },
    editCurrent(info) {
      this.editId = info.id;
      this.isEdit = true;
      this.editVisible = true;
    },
    async getSendAddressList() {
      let clinicAddressList = await clinicAddress({
        refCode: this.info["clinicId"],
        refType: "CLINIC"
      });
      let list = [];
      clinicAddressList.map(item => {
        list.push({
          value: item.id,
          label: `${item.receiveUser}- ( ${
            item.contactWay === "2"
              ? `${item.phoneArea}-${item.phone}`
              : item.phone
          } ) -${item.provinceName}-${item.cityName}-${item.areaName}-${
            item.address
          }`
        });
      });
      this.clinicAddressList = list;
      PublicSendAddressList({
        refCode: localStorage.getItem("userId"),
        refType: "DOCTOR"
      }).then(res => {
        this.addressList = res;
      });
    },
    getBeforeReceiveAddress() {
      const address = this.info["receiveAddress"];
      return `${address?.provinceName}${address?.cityName}${address?.areaName}${address?.address}`;
    },
    renderAddressText() {
      const {
        receiver,
        sender,
        contactWay,
        phoneArea,
        phone,
        provinceName,
        cityName,
        areaName,
        receiveUser,
        address
      } = this.doctorAddressInfo;
      return `${sender || receiver || receiveUser}- ( ${
        contactWay === "2" ? `${phoneArea}-${phone}` : phone
      } ) -${provinceName}-${cityName}-${areaName}-${address}`;
    },
    showDoctorAddressDia() {
      this.showDoctorAddress = true;
    },
    cancel() {
      this.$emit("closeContinue");
    },
    confirm() {
      const {
        phone,
        provinces,
        address,
        receiveType,
        receiver,
        phoneArea,
        receiveAddressId,
        contactWay
      } = this.userForm;
      if (receiveType === "2") {
        if (!receiver) {
          return notifyMsg(
            this,
            "warning",
            this.$t("cases.createUser.qtxsjrxm"),
            2000
          );
        }
        if (contactWay == 2) {
          if (!phoneArea) {
            return notifyMsg(this, "warning", this.$t("birdie.qtxqh"));
          }
          if (!phone) {
            return notifyMsg(this, "warning", this.$t("birdie.qtxdhhm"));
          }
          if (phoneArea && phone) {
            const areaPhonePass = /^0\d{2,4}$/.test(phoneArea);
            const phonePass = /^\d{7,8}$/.test(phone);
            if (!areaPhonePass || !phonePass) {
              if (!areaPhonePass) {
                return notifyMsg(this, "warning", this.$t("birdie.qhsryw"));
              }
              if (!phonePass) {
                return notifyMsg(this, "warning", this.$t("birdie.dhhmsryw"));
              }
            }
          }
        } else {
          if (
            !phone ||
            !/^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/.test(
              phone
            )
          ) {
            if (!phone) {
              return notifyMsg(
                this,
                "warning",
                this.$t("cases.createUser.qsrsjrsjhm"),
                2000
              );
            } else if (
              !/^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/.test(
                phone
              )
            ) {
              return notifyMsg(this, "warning", this.$t("birdie.sjrsjhmyw"));
            }
          }
        }
        if (!provinces || !address) {
          return notifyMsg(
            this,
            "warning",
            this.$t("cases.createUser.qtxssqjdz"),
            2000
          );
        }
      }
      if (receiveType === "3") {
        if (this.doctorAddressId === "") {
          return notifyMsg(this, "warning", this.$t("yssh.qxzysshdz"));
        }
      }
      if (receiveType === "1") {
        if (receiveAddressId === "") {
          return notifyMsg(this, "warning", this.$t("yssh.qxzzsshdz"));
        }
      }
      const result = this.userForm;
      if (receiveType === "3") {
        result["receiveAddressId"] = this.doctorAddressId;
      }
      if (receiveType !== "2") {
        delete result["contactWay"];
      }
      this.$emit("confirmContiune", pickBy(result));
    },
    changeContactWay(e) {
      this.userForm.phoneArea = "";
      this.userForm.phone = "";
    },
    getMessage() {
      //张xx  13259030322 浙江省杭州市下城区xx大厦xx号
      const [res] = AddressParse.parse(this.address);
      let provinces = [];
      if (res.province) {
        const list = this.CITY.filter(item => item.name === res.province);
        if (list.length) {
          const cityList = list[0].sub.filter(item => item.name === res.city);
          const areaList = cityList[0].sub.filter(
            item => item.name === res.area
          );
          provinces.push(list[0].id);
          if (cityList.length) {
            provinces.push(cityList[0].id);
            if (areaList.length) provinces.push(areaList[0].id);
          }
        }
      }
      if (res.mobile) {
        this.userForm.phone = res.mobile;
        this.userForm.contactWay = "1";
      }
      if (res.phone) {
        let areaPhone = res["phone"].split("-");
        this.userForm.contactWay = "2";
        this.userForm.phoneArea = areaPhone[0];
        this.userForm.phone = areaPhone[1];
      }
      this.userForm.provinces = provinces;
      this.userForm.receiver = res.name;
      this.userForm.address = res.details;
    },
    resetMessage() {
      this.address = "";
    },
    getCity() {
      getCityList().then(data => {
        this.CITY = data;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.address-dia-header {
  height: 58px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .close-dia {
    cursor: pointer;
    width: 24px;
    height: 24px;
  }

  .title {
    font-size: 18px;
    color: #333333;
    display: flex;
    align-items: center;

    .add-address {
      cursor: pointer;
      width: 116px;
      height: 36px;
      font-size: 14px;
      color: #fcc80e;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 24px;
        margin-right: 4px;
      }
    }
  }
}

.address-dia-content {
  padding: 10px 30px;
  height: 600px;
  overflow-y: auto;

  .no-data {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      width: 184px;
      margin-bottom: 24px;
    }

    span {
      font-size: 14px;
      line-height: 20px;
      color: #999999;
    }
  }

  .address-item {
    margin-bottom: 10px;
  }
}

.address-dia-footer {
  height: 30px;
  box-shadow: 0px -2px 8px 0px rgba(0, 0, 0, 0.12);
}

.continue-shipping {
  .shipping-tips {
    padding: 10px;
    background-color: #f4f4f4;
    border-radius: 4px;
    font-size: 16px;
    line-height: 16px;
    color: #666666;

    :last-child {
      margin-top: 16px;
    }
  }

  .before-info {
    font-size: 16px;
    line-height: 20px;
    color: #999999;

    p {
      margin-bottom: 4px;
    }

    p:first-child {
      margin-bottom: 30px;
      line-height: 30px;
    }

    .label {
      color: #333333;
    }
  }

  .receive-address {
    display: flex;
    align-items: center;

    .address-label {
      width: 1.3rem;
      font-size: 16px;
      margin-right: 20px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      color: #333 !important;

      &::before {
        content: "";
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: #ed4027;
        margin-right: 4px;
      }
    }

    .address-select {
      width: 316px;
      height: 40px;
    }
  }

  .doctor-address {
    display: flex;
    align-items: center;

    .address-label {
      justify-content: flex-end;
      font-size: 16px;
      margin-right: 20px;
      display: flex;
      align-items: center;
      color: #333333 !important;
      margin-left: 10px;

      &::before {
        content: "";
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: #ed4027;
        margin-right: 4px;
      }
    }

    .single_right {
      display: flex;
      align-items: center;

      span {
        cursor: pointer;
        color: #fcc80e;
        font-size: 14px;
      }
    }
  }

  .doctor-select,
  .doctor-select-active {
    cursor: pointer;
    width: 276px;
    height: 40px;
    line-height: 40px;
    border-radius: 4px;
    padding: 0 20px;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 32px;
  }

  .doctor-select {
    background-color: #f4f4f4;
    color: #999999;
  }

  .doctor-select-active {
    border: 1px solid #bbbbbb;
    color: #333333;
  }

  .message-add {
    //margin-top: 20px;
    height: 100px;
    width: 370px !important;
    border: 1px solid #bbbbbb;
    border-radius: 5px;
    padding: 10px;
  }

  .message-add::placeholder {
    font-size: 14px;
    line-height: 20px;
    color: rgb(153, 153, 153);
  }

  .message-parse {
    display: inline-block;
    margin-left: 10px;
    padding-bottom: 10px;
    color: rgb(252, 200, 14);
    cursor: pointer;
  }

  .message-reset {
    display: inline-block;
    margin-left: 10px;
    padding-bottom: 10px;
    cursor: pointer;
  }

  .iup_box {
    input {
      width: 100%;
      height: 0.42rem;
    }
  }

  .receive-content {
    padding: 20px 0;
    font-size: 16px !important;
  }

  .f_single {
    display: flex;
    align-items: center;
    margin-bottom: 0.25rem;

    .f_single_left {
      margin-right: 0.2rem;
      font-size: 16px;
      width: 1.3rem;
      text-align: right;
      float: right;
      color: $main_theme_color_333;
      white-space: nowrap;

      span {
        color: $main_theme_color_red;
        font-size: 0.14rem;
      }
    }

    .f_single_right {
      flex: 1;
      border-radius: 0.02rem;
      color: $main_theme_color_333;
      position: relative;

      input {
        width: 2.74rem;
        height: 0.4rem;
        box-sizing: border-box;
        outline: none;
        padding-left: 0.19rem;
        border: 1px solid #bbbbbb;
        border-radius: 4px;
      }

      .select_box {
        display: flex;
        align-items: center;

        select {
          width: 2rem;
          height: 0.4rem;
          border: none;
          background-color: #f4f4f4;
          padding: 0.1rem 0.19rem;
          box-sizing: border-box;
        }
      }
    }
  }

  .dia-header {
    height: 58px;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    border-bottom: 1px solid #e5e5e5;
    color: #333333;

    img {
      cursor: pointer;
    }
  }

  .dia-content {
    height: 478px;
    padding: 20px;
    overflow-y: auto;
  }

  .dia-footer {
    padding: 0 20px;
    height: 56px;
    box-shadow: 0px -2px 8px 0px rgba(51, 51, 51, 0.08);
    display: flex;
    align-items: center;
    justify-content: center;

    .confirm,
    .cancel {
      cursor: pointer;
      width: 96px;
      height: 36px;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      color: #333333;
    }

    .cancel {
      border: 1px solid #bbbbbb;
    }

    .confirm {
      background-color: #fcc80e;
      margin-left: 32px;
    }
  }
}

/deep/ .el-dialog {
  border-radius: 8px;
}

/deep/ .address-edit-dia .el-dialog {
  margin-top: 15vh !important;
}

/deep/ .address-dia .el-dialog__header {
  padding: 0px;
}

/deep/ .address-dia .el-dialog__body {
  padding: 0px;
}

/deep/ .address-dia .el-dialog {
  border-radius: 8px;
  margin-top: 15vh !important;
}

/deep/ .el-dialog__header {
  padding: 0px !important;
}

/deep/ .el-dialog__body {
  padding: 0px !important;
}

.el-icon-close {
  cursor: pointer;
  font-size: 24px;
}

.el-icon-close:hover {
  color: $main_theme_color;
}
</style>
